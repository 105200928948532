import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const HopOnHopOff: React.FC<any> = () => {
  const { t } = useTranslation();

  const gridStyles = {
    backgroundColor: "blanchedalmond",
    width: 1,
    paddingBottom: 1,
    marginBottom: 2
  };

  const stops : { [key: string]: any } = {
    'Business Organization, Incorporation, Joint Venture, Location, etc​.': {
      description: [
        'Incorporation: Law dictates how to legally form your business organization/enterprise.',
        'Joint Venture: Legal terms for business model partnerships.',
        'Location: Where the place of business operated.'
      ]
    },
      'Business Privacy Policy, Objects & Target Products/Services': {
        description: [
          'Business Policy: Laws ensuring ethical operations.',
          'Object: Legal goal is to minimize risks.',
          'Target Products/Services: Laws regulate product safeness and consumer protection.'
        ]
      },
      'Management, Employment & Digital Management': {
        description: [
          'Management: Legal guidelines direct company leadership practices and digital working time clock.',
          'Employment: Labor laws govern hiring, pay, workplace and other conditions.',
          'Digital Connection: Laws cover online networking systems, including data protection.'
        ]
      },
      'Logistics & Suppliers': {
        description: [
          'Products/Services: Laws dictate quality, safety, and labeling requirements.',
          'Supply and Raw Materials: Regulations govern sourcing and sustainability.',
          'Finished Products: Legal standards must be met for sale and distribution.'
        ]
      },
      'Sales, Market Segment, Advertisement & Digital Marketing​': {
        description: [
          'Sales: Consumer protection laws govern transactions.',
          'Advertisement: Regulations control claims and disclosures.',
          'Market Segment: Anti-discrimination laws affect targeting.',
          'Digital Marketing: Laws like PDPA regulate data collection and use.'
        ]
      },
      'Contracts, Risk Management & Collection and Receipts, etc.​': {
        description: [
          'Contracts: Laws outline enforceable terms and conditions.',
          'Collection: Legal rules govern debt recovery methods.',
          'Receipt: Regulations stipulate record-keeping and proof of transaction.'
        ]
      },
      'Tax filings, Account & Banks​': {
        description: [
          'Tax Filings: Tax codes dictate reporting and payment obligations.',
          'Account: Laws govern financial record-keeping.',
          'Banks: Regulatory compliance is essential for banking transactions.'
        ]
      },
      'Corporate filings, Compliance, Minutes Book Stock/Share Certificates​': {
        description: [
          'Corporate Filing: Laws require formal documentation for business activities.',
          'Compliance: Regulatory frameworks dictate business conduct and reporting.',
          'Minutes Book: Legally mandated for documenting corporate decisions.',
          'Stock/Share Certificate: Laws regulate issuance and tracking of ownership.'
        ]
      },
      'Intellectual Property (IPs), Trademarks, Patents, Copyrights & Trade Secrets​': {
        description: [
          'Trademarks: Legal guidelines govern brand identity protection.',
          'Copyright: Laws protect original creative works.',
          'Patent: enhancing business opportunity on R&D industrial / commercial application',
          'Trade Secrets: Laws secure confidential business information.'
        ]
      },
      'Merger, Acquisition and Due Diligence MLA​': {
        description: [
          'Merger: Legal protocols govern the fusion of two companies.',
          'Acquisition: Laws dictate how one company can purchase another.',
          'Due Diligence: Legal standards for evaluating risks in mergers, acquisitions, and Material Legal Agreements (MLA).',
        ]
      },
      'Commercial disputes & Litigation​': {
        description: [
          'Commercial Disputes: Laws outline resolution mechanisms for business conflicts.',
          'Litigation: Legal procedures govern court-based dispute resolution.',
        ]
      },
      'Moving Forward and Restructuring​': {
        description: [
          'Moving Forward: Legal guidelines facilitate business growth and expansion.',
          'Restructuring: Laws govern how to modify corporate form or operations to surpass multiple debts.',
        ]
      },
  };

  return (
    <Grid>
    <Grid
      container
      sx={{ ...gridStyles }} 
      justifyContent="center"
      className="service-box"
    >
      <Grid>
        <Typography className="hop-title" sx={{color:'#4F4A41',marginBottom: '1rem',marginTop: '4rem',textAlign:'center'}} variant="h4"  component="h4">
          {t('Hop On and Hop Off')}
        </Typography>
        <Typography  sx={{color:'#4F4A41',textAlign:'center', marginBottom: '1rem'}} variant="h6">
            {t(`A look at the importance of law`)}
          </Typography>
      </Grid>
    </Grid>
      <div style={{margin: 'auto', maxWidth: '80%'}}>
        {
        Object.keys(stops).map(stop_name => {
          return (
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t(stop_name)}
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {
                  stops[stop_name].description.map((desc : any) => {
                    return(<li>{desc}</li>)
                  })
                }
              </ul>
            </AccordionDetails>
          </Accordion>
          )
        })
        }
      </div>
    </Grid>
  );
};
export default HopOnHopOff;
