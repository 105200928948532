// import { history } from "../index";
import {
  OK,
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  server,
  TOKEN,
  MEMBER_ID,
  LOGOUT,
} from "../services/Constants";
import { User } from "../types/user.type";
import { httpClient } from "../services/httpclient";
import { LoginResult } from "../types/authen.type";
import * as menuconfigActions from "../actions/menuconfig.action";

export const setLoginFetchingToState = () => ({
  type: LOGIN_FETCHING,
});

export const setLoginSuccessToState = (payload: LoginResult,tokenpayload:any) => ({
  type: LOGIN_SUCCESS,
  payload,
  tokenpayload,
});

export const setLoginFailedToState = () => ({
  type: LOGIN_FAILED,
});

export const setLogoutToState = () => ({
  type: LOGOUT,
});

export const login = (user: User, navigate: any) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setLoginFetchingToState());
      // connect
      const result = await httpClient.post<LoginResult>(server.LOGIN_URL, user);
      if (result.status === 200) {
        // setTimeout(() => {
          localStorage.setItem(TOKEN, result.data.token!);
          localStorage.setItem(MEMBER_ID,result.data.user_id!);
          const token = JSON.stringify(result.data.token);
          let base64Url = token.split(".")[1];
          let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          let tokenpayload;
          try{
           tokenpayload = decodeURIComponent(atob(base64).split("").map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
        dispatch(menuconfigActions.getMENU_CONFIGFindByroleIdforHeader(JSON.parse(tokenpayload).role_id || 0)); 
        dispatch(setLoginSuccessToState({result: OK, token, message: "Login successfully",},JSON.parse(tokenpayload)));
        await httpClient.post(server.LOGINOUTLOG+"/add");
      }catch (e) {
        tokenpayload = unescape(atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    // dispatch(setLoginSuccessToState(result.data,""));
    dispatch(logout);
      }
        if((JSON.parse(tokenpayload).user_type == "User" && JSON.parse(tokenpayload).member_roll == "Admin")  || (JSON.parse(tokenpayload).user_type == "User" && JSON.parse(tokenpayload).member_roll == "IT Admin")){
          navigate("/my-request-tracking");
        }else if(JSON.parse(tokenpayload).user_type == "Member"){
          navigate("/my-request");
        }
        else{
          navigate("/dashboard");
        }
          // alert("Login Successfully");
          
        // }, 1000);
      } else {
        dispatch(setLoginFailedToState());
      }
    } catch (error) {
      // error
      dispatch(setLoginFailedToState());
    }
  };
};

export const restoreLogin = () => {
  return async (dispatch: any) => {
    if(localStorage.getItem(TOKEN)){
      const result = await httpClient.get<LoginResult>(`${server.MEMBER_URL}/restoreLogin`);
      if(result.status !== 200){
        return;
      }else{
        if (localStorage.getItem(TOKEN)) {
    const token = JSON.stringify(localStorage.getItem(TOKEN));
          let base64Url = token.split(".")[1];
          let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          let tokenpayload;
          try{          tokenpayload = decodeURIComponent(atob(base64).split("").map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
        dispatch(menuconfigActions.getMENU_CONFIGFindByroleIdforHeader(JSON.parse(tokenpayload).role_id || 0));  
        dispatch(setLoginSuccessToState({result: OK, token, message: "Login successfully",},JSON.parse(tokenpayload)));
      }catch (e) {
        tokenpayload = unescape(atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    dispatch(logout);
      }
    }
  }
  }else{
    dispatch(logout);
  }

  };
};

export const GetLogInOutLog = (navigate: any) => {
  return async (dispatch: any) => {
    try {
      await httpClient.put(server.LOGINOUTLOG+"/update");
    } catch (error) {
    }
  };
};

export const logouttologinpage = (navigate: any) => {
  return async(dispatch: any) => {
  //  await GetLogInOutLog(dispatch)
  // const resultlog = await httpClient.put(server.LOGINOUTLOG+"/update");
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(MEMBER_ID);
    // localStorage.clear();
    // localStorage.setItem('i18nextLng', 'en');
    dispatch(setLogoutToState());
    dispatch(menuconfigActions.loadresetdataMENU_CONFIG());  
    console.log("Logout successfully");
    // alert("Logout successfully");
    navigate("/login");
  };
};


export const logout = (navigate: any) => {
  return (dispatch: any) => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(MEMBER_ID);
    // localStorage.clear();
    // localStorage.setItem('i18nextLng', 'en');
    dispatch(setLogoutToState());
    dispatch(menuconfigActions.loadresetdataMENU_CONFIG());  
    console.log("Logout successfully");
    // alert("Logout successfully");
    navigate("/home");
  };
};
