import * as React from "react";
import "./HomePage.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import Typography from "@mui/material/Typography";
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PrintIcon from '@mui/icons-material/Print';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { fontSize } from "@mui/system";
import CardMedia from '@mui/material/CardMedia'
import { highlightTrailingWhitespace } from "jest-matcher-utils";
import SME_Mate_ENG from "../../layouts/assets/SME_Mate_ENG.mp4";


type HomePageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const HomePage: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);

  React.useEffect(() => {
    dispatch(verifyEmailActions.setVerifyEmailFetchingToState());
    if(registerReducer.result){
      Swal.fire({
        title: 'สมัครสมาชิก !!',
        text: 'สมัครสมาชิก สำเร็จ !!',
        icon: 'success',
      })
      // dispatch(registerActions.setRegisterFetchingToState());
      return
    }
  }, []);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
 

  return (
    <Grid>
      <Grid
        container
        sx={{ ...gridStyles }} 
        justifyContent="center"
      >
        <Grid className='contact-col' sx={{textAlign:'center',mb:10}}>
          <Typography sx={{color:'#4F4A41',marginBottom: '1rem',marginTop: '4rem',textAlign:'center'}} variant="h5"  component="h5">
            {t('SME MATE')}
          </Typography>
          <Typography sx={{color:'#4F4A41',marginBottom: '4rem',textAlign:'center'}} variant="h5"  component="h5">
            {t('HELP MANAGING YOUR BUSINESS')}
          </Typography>
        </Grid>


        <Grid className='contact-row'  item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10}}>
          <InsertDriveFile  sx ={{color:'#876445',width:"100%",height:"80px",}}/>
          <Typography sx={{color:'#876445',marginBottom: '4rem',fontSize:"14px" }}>
            {t('Your Business Information')}
          </Typography>
        </Grid>
        <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10,mb:10}}>
          <HorizontalRuleIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
        </Grid>

        <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10}}>
          <PersonAddIcon  sx ={{color:'#876445',width:"100%",height:"80px" }}/>
          <Typography sx={{color:'#876445',marginBottom: '4rem',fontSize:"14px" }}>
            {t('Register with us')}
          </Typography>
        </Grid>
        <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10,mb:10}}>
          <HorizontalRuleIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
        </Grid>

        <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10}}>
          <AssignmentIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
          <Typography sx={{color:'#876445',marginBottom: '4rem',fontSize:"14px"}}>
            {t('Document Management')}
          </Typography>
        </Grid>
         <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10,mb:10}}>
          <HorizontalRuleIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
        </Grid>

        <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10}}>
          <PrintIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
          <Typography sx={{color:'#876445',marginBottom: '4rem',fontSize:"14px"}}>
            {t('Download and Print')}
          </Typography>
        </Grid>
         <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10,mb:10}}>
          <HorizontalRuleIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
        </Grid>

        <Grid className='contact-row' item xs={12} sm={1} md={1} lg={1} xl={1} sx={{textAlign:'center',mt:-10}}>
          <HeadsetMicIcon  sx ={{color:'#876445',width:"100%",height:"80px"}}/>
          <Typography sx={{color:'#876445',marginBottom: '4rem',fontSize:"14px"}}>
            {t('Exclusive Support')}
          </Typography>
        </Grid>
        
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
        }}
      >
        {/* <CardMedia  component="iframe" src={"/"}/> */}
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{mt:5}}
      >
        <CardMedia 
         sx={{
          maxWidth: 800,
          margin: "0 auto",
          padding: "0.1em",
        }}
         controls component="video" src={SME_Mate_ENG}/> 
         {/* autoPlay */}
          </Grid>
      <Grid
        container
        justifyContent="center"
      >
        <Grid className='prof-col'>
          <Typography variant="h6" sx={{color:'#4F4A41',marginTop: '4rem', marginBottom: '1rem',textAlign:'center', fontWeight: 'bold'}} >
            {t(` "We fill SME's heart with know-how and experience to move forward, challenging the outer world and beyond."`)}
          </Typography>
          <Typography className='quote' sx={{color:'#4F4A41',marginBottom: '0.5rem',textAlign:'center'}}>
            {t('━ Prof. Dej-Udom Krairit')}
          </Typography>
          <Typography className='quote' sx={{color:'#4F4A41',marginBottom: '2rem',textAlign:'center', fontWeight: 'bold'}}>
            {t('CEO')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

};


export default HomePage;
