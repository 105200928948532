import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link,useMatch, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { useTranslation } from "react-i18next";
import { server, TOKEN,MEMBER_ID } from "../../../services/Constants";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as companyListActions from "../../../actions/company.list.action";
import * as requestListActions from "../../../actions/request.list.action";
import * as requestEditTypeListActions from "../../../actions/requesttypeedit.list.action";
import * as requestEditSubTypeListActions from "../../../actions/requestsubtypeedit.list.action";
import * as loginActions from "../../../actions/login.action";
import * as requestEditActions from "../../../actions/request.edit.actions";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import * as requestDeleteActions from "../../../actions/request.delete.actions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";
import * as requestTypeListActions from "../../../actions/requesttype.list.action";
import * as requestActions from "../../../actions/request.action";
import * as requestSubTypeListActions from "../../../actions/requestsubtype.list.action";
import i18next from 'i18next';
import * as registerActions from "../../../actions/register.action";
const Swal = require('sweetalert2')




export default function RequestEditPage() {
    const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
    const registerReducer = useSelector((state: RootReducers) => state.registerReducer);
    const CompanyListReducer = useSelector((state: RootReducers) => state.companyListReducer);
    const requesEditTypeListReducer = useSelector((state: RootReducers) => state.requesEditTypeListReducer);
    const requesEditTypeSubListReducer = useSelector((state: RootReducers) => state.requesEditTypeSubListReducer);
    const requesListReducer = useSelector((state: RootReducers) => state.requesListReducer);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const match = useMatch("edit-request/:id");
    let req_id = match?.params.id;
    const [req_type_select, setRequestTypeSelect] = React.useState(0);
    const [req_sub_type_load, setRequestSubTypeLoad] = React.useState(0);
    const requesTypeListReducer = useSelector((state: RootReducers) => state.requesTypeListReducer);
    // const request_type_list = requesTypeListReducer.result
    const [request_type_list, setRequesttypelist] = React.useState<any[]>([]);
    const [request_sub_type_list, setRequestSubTypeList] = React.useState<any[]>([]);
    const [request_sub_type_list_auto, setRequestSubTypeListAuto] = React.useState<any[]>([]);
    const requestSubTypeListReducer = useSelector((state: RootReducers) => state.requestSubTypeListReducer);

    // const company_list = CompanyListReducer.result;
    const [company_list, setcompany_list] = React.useState<any[]>([]);  
    
    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        CompanyListReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;
        })
        if(i18next.language == 'en'){
            if(CompanyListReducer.result.length > 0){
                dataList[dataList.length - 1] = {
                    id: 0, 
                    th_company_name: "No business"
                }
            }else{
                dataList[0] = {
                    id: 0, 
                    th_company_name: "No business"
                }
            }
          }else if(i18next.language == 'th'){
            if(CompanyListReducer.result.length > 0){
                dataList[dataList.length - 1] = {
                    id: 0, 
                    th_company_name: "ไม่มีข้อมูลธุรกิจ"
                }
            }else{
                dataList[0] = {
                    id: 0, 
                    th_company_name: "ไม่มีข้อมูลธุรกิจ"
                }
            }
          }else{
            return;
          }

        setcompany_list(dataList);
    }, [CompanyListReducer.result,company_list,i18next.language]);

    const [checkedbox, setCheckedBox] = React.useState(true);
    const [selectedRequestType, setSelectedRole] = React.useState(() => {
    const foundRequestType = request_type_list.find(option => Number(option.id) === Number(req_type_select));
        return foundRequestType || null;
    });
    //get data input
    const [datasheet_id, setCompanySelected] = React.useState<any>({}); 
    const [statusD, setStatusD] = React.useState("1");
    const [statusS, setStatusS] = React.useState("2");
    const [remark, setRemark] = React.useState("");
    const [req_type, setRequestTypeSelected] = React.useState<any[]>([]);
    const [req_sub_type, setRequestSubTypeSelected] = React.useState<any[]>([]);
    const start = Date.now();
    const [requestDate, setRequestDate] = React.useState<Dayjs | null>(dayjs(start));
    const [requestDateValue, setRequestValue] = React.useState<Dayjs | null>(dayjs(start));
    const [first_name, setfirst_name] = React.useState<string>("");
    const [last_name, setlast_name] = React.useState<string>("");
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
        //   navigate("/login");
            dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
        const callawaitfunction = async () =>{
           await dispatch(companyListActions.loadCompanyLiest(localStorage.getItem(MEMBER_ID)));
           await dispatch(requestListActions.loadRequestList(Number(req_id)));
           await dispatch(requestTypeListActions.loadRequestTypeList());
           await dispatch(requestSubTypeListActions.loadRequestSubTypeList());
           await dispatch(requestActions.loadRequestById(Number(req_id)));
           
        }
        callawaitfunction();
    }, []);


    //load data
    React.useEffect(() => {
        if(requesListReducer.result.length > 0){
            // console.log(requesListReducer.result)
        requesListReducer.result.forEach((item) => {
            if(item.datasheet_id == 0){
                if(i18next.language == 'en'){
                    setCompanySelected({th_company_name: "No business", id: 0})
                }else if(i18next.language == 'th'){
                    setCompanySelected({th_company_name: "ไม่มีข้อมูลธุรกิจ", id: 0})
                }else{
                    return;
                }
            }else{
                setCompanySelected({th_company_name: item.company_name, id: item.datasheet_id})
            }
            
            setRemark(item.remark) 
            setRequestDate(dayjs(new Date(item.duedate)))
        })
    }
    }, [requesListReducer.result,i18next.language]);

    React.useEffect(() => {
        if(requesTypeListReducer.result.length >0){
          let  dataList:any[] = [];
          let index = 0;
          requesTypeListReducer.result.forEach((item) =>{
            if(i18next.language == 'en'){
              item.request_name_show = item.request_type_name_en;
            }else if(i18next.language == 'th'){
              item.request_name_show = item.request_type_name;
            }else{
              return;
            }
              dataList[index] = item;
              index += 1;
          })
        //   console.log("dataList request")
        //   console.log(dataList)
          setRequesttypelist(dataList)
        }
    }, [requesTypeListReducer.result,i18next.language]);

    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        console.log(requestSubTypeListReducer.result)
        if(requestSubTypeListReducer.result.length > 0){
            // console.log(requestSubTypeListReducer.result)
            requestSubTypeListReducer.result.forEach((item) =>{
                if(i18next.language == 'en'){
                    item.request_sub_show = item.request_sub_name_en;
                }else if(i18next.language == 'th'){
                    item.request_sub_show = item.request_sub_name;
                }else{
                    return;
                }
                dataList[index] = item;
                index += 1;
            })
            setRequestSubTypeList(dataList)
        }
       
        // setRequestSubTypeListAuto(dataList)
    }, [requestSubTypeListReducer.result,i18next.language]);
   
    React.useEffect(() => {
        if(requesReducer.result[0]){
            setRequestSubTypeLoad(requesReducer.result[0].request_type)
            setRequestTypeSelect(requesReducer.result[0].request_type)
            onLoadRequestSubType(requesReducer.result[0].request_type)
            setCheckedBox(false)
            console.log("requesReducer.result[0]")
            console.log(requesReducer.result[0])
        }
    }, [requesReducer.result]);
    React.useEffect(() => {
        if(requesReducer.result.length > 0){
            dispatch(registerActions.loadMemberByID(requesReducer.result[0].user_type,Number(requesReducer.result[0].user_create)));
        }
    }, [requesReducer.result, dispatch]);

    React.useEffect(() => {
        if(registerReducer.result){
            setfirst_name(registerReducer.result[0].first_name)
            setlast_name(registerReducer.result[0].last_name)
        }
    }, [registerReducer.result,requesReducer.result]);

    React.useEffect(() => {
        if(req_sub_type_load > 0){
            dispatch(requestEditSubTypeListActions.loadRequestEditSubTypeList(Number(req_id),Number(req_sub_type_load)));
        }
    }, [req_sub_type_load]);

    React.useEffect(() => {
        // console.log("requesEditTypeListReducer.result")
        // console.log(requesEditTypeListReducer.result)
    }, [requesEditTypeListReducer.result]);

    React.useEffect(() => {
        const foundRole = request_type_list.find(option => Number(option.id) === Number(req_type_select));
        setSelectedRole(foundRole || null);
    }, [request_type_list,req_type_select]);

    const onLoadRequestSubType = (request_id:number) => {
        setRequestSubTypeSelected([])
        // console.log(request_sub_type_list)
        let loadListByReqId:any = [];
        let loadListIndex = 0;
        request_sub_type_list.find((item) => {
            if(Number(request_id) == Number(item.requesttype_id)){
                if(i18next.language == 'en'){
                    item.request_sub_show = item.request_sub_name_en;
                }else if(i18next.language == 'th'){
                    item.request_sub_show = item.request_sub_name;
                }else{
                    return;
                }
                loadListByReqId[loadListIndex] = item;
                loadListIndex += 1;
            }
            
        })
        // console.log(loadListByReqId)
        setRequestSubTypeListAuto([...loadListByReqId])
    };

    React.useEffect(() => {
        let dataList:any = [];
        requesEditTypeSubListReducer.result.forEach((item,index)=>{
            if(i18next.language == 'en'){
                item.request_sub_show = item.request_sub_name_en;
            }else if(i18next.language == 'th'){
                item.request_sub_show = item.request_sub_name;
            }else{
                return;
            }
            dataList[index] = item;
        })
        setRequestSubTypeListAuto(requesEditTypeSubListReducer.result)
        // setRequestSubTypeSelected([])
        // console.log("requesEditTypeSubListReducer.result")
        // console.log(requesEditTypeSubListReducer.result)
        let dataSub:any = [];
        requesEditTypeSubListReducer.result.forEach((item,index)=>{
            if(Number(item.check_box) == 1){
                dataSub.push({request_sub_type_id: `${item.id}`})
            }
        })
        setRequestSubTypeSelected(dataSub)
    }, [requesEditTypeSubListReducer.result,i18next.language]);

    //date
    const setReqestDateValue = (newValue: Dayjs | null) => {
        setRequestDate(newValue);
    };
    // console.log(requestDate)
    //get requestype
    const getRequestTypeSelect = (e:any) => {
        // Destructuring
        const { value, checked } = e.target;
        // console.log(value + " : " +checked)
        // Case 1 : The user checks the box
        if (checked) {
            let data = req_type;
            data.push({request_type_id: value});
            setRequestTypeSelected(data)
        }
        // // Case 2  : The user unchecks the box
        else {
            let filtered = req_type.filter(item=>{
                return item.request_type_id != value;
            });
            setRequestTypeSelected(filtered)
        }

    };
     //get request subtype
    const getRequestSubTypeSelect = (e:any) => {
        // Destructuring
        const { value, checked } = e.target;
        
        // Case 1 : The user checks the box
        if (checked) {
            let data = req_sub_type;
            data.push({request_sub_type_id: value});
            setRequestSubTypeSelected(data)
        }
        // Case 2  : The user unchecks the box
        else {
            let filtered = req_sub_type.filter(item=>{
                return item.request_sub_type_id != value;
            });
            setRequestSubTypeSelected(filtered)
        }   
    };

    const SaveDraftRequest = (event:any) => {
        if(dayjs(requestDate).format('DD-MM-YYYY') >= dayjs(start).format('DD-MM-YYYY')){
        }
        else{
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `${t("Please Select Request Due Date")}`,
                icon: "warning",
            });  
            return;
        }
        event.preventDefault();
        // if(datasheet_id == 0){
        //     Swal.fire({
        //         title: `${t("Check Data")}`,
        //         text: "กรุณาเลือกประเภทของธุรกิจ",
        //         icon: "warning",
        //     });  
        //     return;
        // }
        if(req_type_select == 0){
            Swal.fire({
                title: `${t("Check Data")}`,
                text: "Please Choose Request Type",
                icon: "warning",
            });  
            return;
        }
        if(req_type_select != 38){
            if(req_sub_type.length == 0){
                Swal.fire({
                    title: `${t("Check Data")}`,
                    text: `${t("Please Choose Sub Request Type")}`,
                    icon: "warning",
                });  
                return;
            }
        }
        // console.log(Number(req_id))
        // console.log(datasheet_id.id)
        // console.log(req_type_select)
        // console.log(req_sub_type)
        // console.log(remark)
        // console.log(statusD)
        let reqDate:any = requestDate?.toISOString().substring(0, 10);
        dispatch(requestEditActions.editRequestEdit(
            Number(req_id),
            datasheet_id.id,
            Number(req_type_select),
            req_sub_type,
            reqDate,
            remark,
            statusD,
            navigate
        ))

    };
    const SaveSubmitRequest = (event:any) => {
        event.preventDefault();
        if(dayjs(requestDate).format('DD-MM-YYYY') >= dayjs(start).format('DD-MM-YYYY')){
        }
        else{
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `${t("Please Select Request Due Date")}`,
                icon: "warning",
            });  
            return;
        }
        // if(datasheet_id == 0){
        //     Swal.fire({
        //         title: `${t("Check Data")}`,
        //         text: "กรุณาเลือกประเภทของธุรกิจ",
        //         icon: "warning",
        //     });  
        //     return;
        // }
        if(req_type_select == 0){
            Swal.fire({
                title: `${t("Check Data")}`,
                text: "Please Choose Request Type",
                icon: "warning",
            });  
            return;
        }
        if(req_type_select != 38){
            if(req_sub_type.length == 0){
                Swal.fire({
                    title: `${t("Check Data")}`,
                    text: `${t("Please Choose Sub Request Type")}`,
                    icon: "warning",
                });  
                return;
            }
        }
        // console.log(Number(req_id))
        // console.log(datasheet_id.id)
        // console.log(req_type_select)
        // console.log(req_sub_type)
        // console.log(remark)
        // console.log(statusD)
        let reqDate:any = requestDate?.toISOString().substring(0, 10);
        dispatch(requestEditActions.editRequestEdit(
            Number(req_id),
            datasheet_id.id,
            Number(req_type_select),
            req_sub_type,
            reqDate,
            remark,
            statusS,
            navigate
        ))
    };

    const ClearValue = () => {
        window.location.reload();
    };

    return (
        <Grid  
            xs={12} sm={12} md={10} lg={5} xl={5} 
            container
            justifyContent="center"
            sx={
                { 
                    margin: '0 auto'
                }
            }
        >
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
                <CardContent>
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                borderBottom: '#ABA9A6 solid 2px',
                                marginBottom: '1rem'
                            }}
                        >
                            <Typography 
                                gutterBottom 
                                variant="h5" 
                                component="h2" 
                                sx={{
                                    color:'#4F4A41',
                                    textAlign: "left",
                                    marginTop: 2
                                }}
                            >
                                {t(`Edit Request`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12}  xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Request Due Date`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12}  xl={3} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                    <MobileDatePicker   
                                        value={requestDate}
                                        onChange={setReqestDateValue}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid 
                                xs={12} sm={2} md={2} lg={2} xl={2} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                >
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1, 
                                }}
                            >
                                {t(`Section 1 Persanal Information`)}
                            </Typography>
                        </Grid>
                                                <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={3} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Firstname`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={8} xl={8} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Typography>{first_name}</Typography>
                            </Grid>

                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={3} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Lastname`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={8} xl={8} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Typography>{last_name}</Typography>
                            </Grid>

                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                xs={12} sm={3} md={3} lg={3} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Business Name`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={5} md={5} lg={5} xl={5} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Autocomplete
                                    value={datasheet_id}
                                    onChange={(event, value: any) =>{
                                        setCompanySelected(value)
                                    }} 
                                    disableClearable={true}
                                    size="small"
                                    clearOnEscape
                                    disablePortal
                                    options={company_list}
                                    getOptionLabel={(options) => options.th_company_name}
                                    sx={{ minWidth: '100%' }}
                                    renderInput={(params) => <TextField {...params}   variant="outlined" 
                                />}
                                />
                            </Grid>
                            <Grid 
                                xs={12} sm={3} md={3} lg={3} xl={3} 
                                paddingLeft={2}
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                >
                                     <Button 
                                    sx={{
                                        backgroundColor: '#CE9461',
                                        '&:hover': {backgroundColor: '#CC704B'}
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        navigate("/datasheet/create")
                                    }}
                                >
                                    {t(`Add New`)}
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1 
                                }}
                            >
                                {t(`Section 2 Choose Request Type`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={6} lg={4} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Request Type`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={7} xl={8} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Autocomplete
                                    value={selectedRequestType}
                                    onChange={
                                        (event, value: any) => {
                                                setRequestTypeSelect(value?.id)
                                                setCheckedBox(false)
                                                onLoadRequestSubType(value?.id)
                                        }
                                    }
                                    
                                    size="small"
                                    disablePortal
                                    options={request_type_list}
                                    getOptionLabel={(options) => options.request_name_show}
                                    sx={{ minWidth: '100%' }}
                                    renderInput={
                                        (params) => <TextField {...params}   variant="outlined"/>
                                    } 
                                    
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1 
                                }}
                            >
                                {t(`Section 3 Choose Sub Request`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={12} xl={12} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                                sx = {{
                                    marginLeft:2
                                }}
                            >
                                {request_sub_type_list_auto.map((name) => (
                                    <div key={name.request_sub_name}>
                                        <FormControlLabel
                                            label={name.request_sub_show}
                                            control={
                                                <Checkbox 
                                                    defaultChecked={name.check_box} 
                                                    value={name.id}
                                                    onChange={(e)=>getRequestSubTypeSelect(e)}
                                                    disabled={checkedbox}
                                                />
                                            }
                                        />
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                        >
                            <TextField 
                                id="outlined-basic" 
                                value={remark} 
                                label={t(`More Information`)} 
                                variant="outlined" 
                                onChange={(e)=>setRemark(e.target.value)}
                                sx = {{
                                    width: '90%',
                                    margin: 'auto'
                                }}
                            />
                        </Grid>
                    <br />
                    <Grid 
                    container   justifyContent="center"
                    >

                        <Button 
                            variant="contained"
                            onClick={SaveDraftRequest}
                            sx={{
                                width: 90,
                                backgroundColor: "#73777B",
                                '&:hover': {backgroundColor: '#2C394B'}
                            }}
                            className="requestButton"
                        >
                            {t(`Draft`)}
                        </Button>
                        <Button 
                            sx={{
                                marginLeft:2,
                                marginRight: 2,
                                width: 90,
                                backgroundColor: "#3E8E7E",
                                '&:hover': {backgroundColor: '#125B50'}
                            }}
                            className="requestButton"
                            variant="contained"
                            onClick={SaveSubmitRequest}
                        >
                            {t(`Submit`)}
                        </Button>
                        {/* <Button 
                            sx={{
                                width: 90,
                            }}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                DeleteValue()
                            }}
                        >
                            Cancel
                        </Button> */}
                        {/* <Button 
                            sx={{
                                width: 90,
                                color: "orange",
                                borderColor: "orange",
                                marginLeft:2,
                            }}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                ClearValue()
                            }}
                        >
                            Clear
                        </Button> */}
                        <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#CE9461',
                                '&:hover': {backgroundColor: '#CC704B'}
                            }}
                            variant="contained"
                            onClick={() => {
                                navigate("/my-request")
                            }}
                        >
                            {t(`Back`)}
                        </Button>
                        
                    </Grid>
                    <br />
                </CardContent>
            </Card>
        </Grid>

  );
}
