// import { history } from "../index";
import {
    REQUEST_LOG_FETCHING,
    REQUEST_LOG_SUCCESS,
    REQUEST_LOG_FAILED,
    // REQUEST_ASSIGN_COUNT_DRAFT,
    // REQUEST_ASSIGN_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Request } from "../types/request.type";
const Swal = require('sweetalert2')

export const setRequestLogFetchingToState = () => ({
    type: REQUEST_LOG_FETCHING,
});
export const setRequestLogSuccessToState = (
  payload: any[],) => (
      {
          type: REQUEST_LOG_SUCCESS,
          payload,
      }
);

export const setRequestLogFailedToState = () => ({
  type: REQUEST_LOG_FAILED,
});
export const loadRequestLogById = (req_id:number) => {
  return async(dispatch: any) => {
      dispatch(setRequestLogFetchingToState());
      getDataRequestLogByID(dispatch,req_id)
  };
};
export const loadRequestDashboard = () => {
  return async(dispatch: any) => {
      dispatch(setRequestLogFetchingToState());
      getRequestDashboard(dispatch)
  };
};
const getDataRequestLogByID = async (dispatch: any, req_id:number) => {
  try {
      const result = await httpClient.get<any[]>(`${server.REQUEST_LOG}/activityByid/${req_id}`);
      dispatch(setRequestLogSuccessToState(result.data));
  }   catch (error) {
      dispatch(setRequestLogFailedToState());
  }
}
const getRequestDashboard = async (dispatch: any) => {
  try {
      const result = await httpClient.get<any[]>(`${server.REQUEST_LOG}/get-request-dashboard-log`);
      dispatch(setRequestLogSuccessToState(result.data));
  }   catch (error) {
      dispatch(setRequestLogFailedToState());
  }
}
  
export const getRequestLogs = (query: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(setRequestLogFetchingToState());
      const result = await httpClient.get<[]>(`${server.REQUEST_LOG}/getSearchData`,query);
      dispatch(setRequestLogSuccessToState(
          result.data 
      ));
         
    } catch (error) {
      dispatch(setRequestLogFailedToState());
    }
  };
};



  