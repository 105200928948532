import * as React from "react";
import {useState} from 'react'; 
import { Fragment } from 'react'; 
import {
  ThemeProvider,
  createTheme,
  styled,
  useTheme,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Header from "./components/layouts/Header";
import NewHeader from "./components/layouts/NewHeader";
import Menu from "./components/layouts/Menu";
import Footer from "./components/layouts/Footer";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoginPage from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/RegisterPage";
import StockPage from "./components/pages/StockPage";
import StockCreatePage from "./components/pages/StockCreatePage";
import StockEditPage from "./components/pages/StockEditPage";
import ReportPage from "./components/pages/ReportPage";
import ResetPage from "./components/pages/ResetPage";
import RequestPage from "./components/pages/RequestPage";
import RequestTheRights from "./components/pages/RequestTheRights";
import RequestSelectPage from "./components/pages/RequestSelectPage";
import RequestTrackingPage from "./components/pages/RequestTrackingPage";
import RequestTrackingHistoryPage from "./components/pages/RequestTrackingHistoryPage";
import RequestTrackingSelectPage from "./components/pages/RequestTrackingSelectPage";
import RequestCreatePage from "./components/pages/RequestCreatePage";
import RequestEditPage from "./components/pages/RequestEditPage";
import RequestSubmitPage from "./components/pages/RequestSubmitPage";
import AboutUs from "./components/pages/AboutUs";
import HomePage from "./components/pages/HomePage";
import ServicesPage from "./components/pages/ServicesPage";
import ContactUs from "./components/pages/ContactUsPage";
import Agreement from "./components/pages/AgreementPage";
import ForgorPassword from "./components/pages/ForgorPassword";
import Dashboard from "./components/pages/Dashboard";
import DataSheetCreatePage from "./components/pages/DataSheetCreatePage";
import DataSheetEditPage from "./components/pages/DataSheetEditPage";
import Business_informationPage from "./components/pages/Business_information";
import Personal_information from "./components/pages/Personal_information";
import ResetPassword from "./components/pages/ResetPassword";
import YourCard from "./components/pages/YourCard";
import AllService from "./components/pages/AllService";
import SuccessPayment from "./components/pages/SuccessPayment";
import CancelPayment from "./components/pages/CancelPayment";
import FAQPage from "./components/pages/FAQPage";
import HopOnHopOff from "./components/pages/HopOnHopOff/HopOnHopOff";


// ============================================ For Admin ================================================ //
import MemberManagement from "./components/pages/Admin/MemberManagement";
import AgreementGroupManagement from "./components/pages/Admin/AgreementGroupManagement";
import AgreementManagement from "./components/pages/Admin/AgreementManagement";
import RolesManagement from "./components/pages/Admin/RolesManagement";
import RolesManagementAdd from "./components/pages/Admin/RolesManagement-Add";
import RolesManagementEdit from "./components/pages/Admin/RolesManagement-Edit";
import UsersManagement from "./components/pages/Admin/UsersManagement";
import UsersManagementAdd from "./components/pages/Admin/UsersManagement-Add";
import UsersManagementEdit from "./components/pages/Admin/UsersManagement-Edit";
import GeneralMenu from "./components/pages/Admin/GeneralMenu";
import DepartmentManagement from "./components/pages/Admin/DepartmentManagement";
import ServiceType from "./components/pages/Admin/ServiceType";
import RequestType from "./components/pages/Admin/RequestType";
import RequestSubType from "./components/pages/Admin/RequestSubType";
import Loginlogs from "./components/pages/Admin/Loginlogs";
import Requestlogs from "./components/pages/Admin/Requestlogs";
import BusinessInformationlogs from "./components/pages/Admin/BusinessInformationlogs";
import RequestlogsDetails from "./components/pages/Admin/RequestlogsDetails";


// ======================================================================================================= //

import { purple, blueGrey, blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "./reducers";
import * as loginActions from "./actions/login.action";
import PublicRoutes from "./router/public.routes";
import ProtectedRoutes from "./router/protected.routes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch } from ".";
import { FaAngleUp } from 'react-icons/fa';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage:
            "url(" +
            `${process.env.PUBLIC_URL}/images/background_menu.jpg` +
            ")",
          width: drawerWidth,
        },
      },
    },
  },
  typography: {
    fontFamily: "Arial",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  spacing: 8,
  palette: {
    primary: process.env.REACT_APP_IS_PRODUCTION == "0" ? blue : blueGrey,
    background: {
      default: "#FFFFFF",
    },
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "90px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "50px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function App() {
  const [open, setOpen] = React.useState(true);
  const loginReducer = useSelector((state: RootReducers) => state.loginReducer);
  const callapiReducer = useSelector(
    (state: RootReducers) => state.callapiReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let mybutton = document.getElementById('BacktopBtn');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  window.onscroll = () => {
		scrollFunction();
	};

	const scrollTopFunction = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const scrollFunction = () => {
		
		if (
			document.body.scrollTop > 760 ||
			document.documentElement.scrollTop > 760
		) {
			if (mybutton) {
				mybutton.style.display = 'block';
			}
		} else {
			if (mybutton) {
				mybutton.style.display = 'none';
			}
		}
	};

  React.useEffect(() => {
    async function Freshapitoken() {
      await dispatch(loginActions.restoreLogin());
    }
    Freshapitoken();
  }, []);

  return (
    <Fragment> 
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* {loginReducer.result && <Header open={open} onDrawerOpen={handleDrawerOpen} />}
        {loginReducer.result && <Menu open={open} onDrawerClose={handleDrawerClose} />} */}
        {/* { <NewHeader open={open}  />} */}
        <Backdrop
          sx={{
            color: "blue",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
          open={callapiReducer.result}
        >
          <CircularProgress color="inherit" size="4rem" />
        </Backdrop>
        <Main
          open={open}
          sx={{
            backgroundColor: "white",
            minHeight: "100vh",
            display: "flex",
            flex: "1 1 auto",
            position: "relative",
            flexDirection: "column",
            maxWidth: "100%",
            padding: "0px",
          }}
          // sx={{ backgroundImage: "url(" + `${process.env.PUBLIC_URL}/images/background.jpg` + ")", height: "100vh" }}
        >
          <NewHeader />
          {/* <DrawerHeader /> */}
          <Box sx={{
             height: "90px",
          }}></Box>
          <Routes>
            {/* Public routes */}
            {/* <Route path="/" element={<PublicRoutes />}> */}
            <Route path="/forgot-reset-password/:id" element={<ResetPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about-us" element={<ServicesPage />} />
            <Route path="/hopon-hopoff" element={<HopOnHopOff />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/service/:id" element={<Agreement />} />
            <Route path="/forgotpassword" element={<ForgorPassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/datasheet/create" element={<DataSheetCreatePage />} />
            <Route path="/datasheet/edit/:id" element={<DataSheetEditPage />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {loginReducer.result && (
              <Route path="/" element={<Navigate to="/dashboard" />} />
            )}
            {!loginReducer.result && (
              <Route path="/" element={<Navigate to="/home" />} />
            )}

            <Route path="*" element={<NotFound />} />

            {/*For Admin */}
            <Route path="/member-management" element={<MemberManagement />} />
            <Route
              path="/service-group-management"
              element={<AgreementGroupManagement />}
            />
            <Route
              path="/service-management"
              element={<AgreementManagement />}
            />
            <Route path="/general-users" element={<UsersManagement />} />
            <Route path="/general-users-add" element={<UsersManagementAdd />} />
            <Route
              path="/general-users-edit/:id"
              element={<UsersManagementEdit />}
            />
            <Route path="/general-roles" element={<RolesManagement />} />
            <Route path="/general-roles-add" element={<RolesManagementAdd />} />
            <Route
              path="/general-roles-edit/:id"
              element={<RolesManagementEdit />}
            />
            <Route path="/general-menu" element={<GeneralMenu />} />
            <Route
              path="/general-department"
              element={<DepartmentManagement />}
            />
            <Route path="/service-type" element={<ServiceType />} />
            <Route path="/request-type" element={<RequestType />} />
            <Route path="/request-sub-type" element={<RequestSubType />} />

            <Route path="/login-logs" element={<Loginlogs />} />
            <Route path="/request-logs" element={<Requestlogs />} />
            <Route path="/business-information-logs" element={<BusinessInformationlogs />} />
            <Route path="/request-logs-details/:id" element={<RequestlogsDetails />} />
            
            
            {/* </Route> */}

            {/* Protected routes */}
            {/* <Route path="/" element={<ProtectedRoutes />}> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stock" element={<StockPage />} />
            <Route path="/stock/create" element={<StockCreatePage />} />

            <Route path="/stock/edit/:id" element={<StockEditPage />} />
            <Route path="/report" element={<ReportPage />} />
            {/* <Route path="/aboutus" element={<AboutUs />} /> */}
            <Route path="/my-request" element={<RequestPage />} />
            <Route path="/request-the-rights-of-data-owner-form" element={<RequestTheRights />} />
            <Route
              path="/my-request-select/:id"
              element={<RequestSelectPage />}
            />
            <Route
              path="/my-request-tracking"
              element={<RequestTrackingPage />}
            >
              <Route
                path="/my-request-tracking/:mode"
                element={<RequestTrackingPage />}
              ></Route>
            </Route>
            <Route
              path="/tracking-history"
              element={<RequestTrackingHistoryPage />}
            />
            <Route
              path="/my-request-tracking-select/:mode/:id"
              element={<RequestTrackingSelectPage />}
            />
            <Route path="/new-request" element={<RequestCreatePage />} />
            <Route path="/edit-request/:id" element={<RequestEditPage />} />
            <Route path="/submit-request/:id" element={<RequestSubmitPage />} />
            <Route
              path="/business-information"
              element={<Business_informationPage />}
            />
            <Route
              path="/personal-information"
              element={<Personal_information />}
            />
          {/* <Route path="/your-cart" element={<YourCard />}/>
          <Route path="/all-services" element={<AllService />}/> */}
          <Route path="/success-payment" element={<SuccessPayment />}/>
          <Route path="/cancel-payment" element={<CancelPayment />}/>
          <Route path="/faq" element={<FAQPage />}/>
          
          
            {/* </Route> */}
          </Routes>
          <DrawerFooter />
          <Footer />
        </Main>
      </Box>
    </ThemeProvider>
    <div className="top-to-btm" id="BacktopBtn" style={{display:"none"}}>
            <FaAngleUp className="icon-position-backtotop icon-style-backtotop" onClick={scrollTopFunction} />
        </div>
    </Fragment> 
  );
}

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);
