import * as React from "react";
import "./ServicesPage.css";
import { Paper, Grid ,Button, ListItemText, ListItem, List, Divider} from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import Typography from "@mui/material/Typography";
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { fontSize } from "@mui/system";
import CardMedia from '@mui/material/CardMedia'
import { highlightTrailingWhitespace } from "jest-matcher-utils";
import { useNavigate } from "react-router-dom";
import qcimage from "../../../components/layouts/assets/quick_access.PNG";
import rqimage from "../../../components/layouts/assets/request.PNG";
import osimage from "../../../components/layouts/assets/online_support.PNG";
import atimage from "../../../components/layouts/assets/assistance.PNG";
import mapimage from "../../../components/layouts/assets/map.PNG";

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const ServicesPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
 

  return (
    <Grid>
      <Grid
        container
        sx={{ ...gridStyles }} 
        justifyContent="center"
        className="service-box"
      >
        <Grid className='contact-col'>
          <Typography className="service-title" sx={{color:'#4F4A41',marginBottom: '1rem',marginTop: '4rem',textAlign:'center'}} variant="h4"  component="h4">
            {t('A service for every SME business need.')}
          </Typography>
          <Grid 
          container
           justifyContent="center" >
          <Grid item xs={12}
    sm={10}
    md={10}
    lg={10}
    xl={10}
   >
          <Typography  sx={{color:'#4F4A41',textAlign:'center'}} variant="h6">
            {t(`At SME Mate, we recognize that managing a business can be demanding and handling documents can be exhausting. And that's a challenge we readily embrace. So, whether you're a new business owner looking to start a business or an owner looking to handle your documents, SME Mate has the service and expertise to get your business on track.`)}
          </Typography>
          </Grid>
          </Grid>
          <br />
        </Grid>

        {/* <Grid 
          className='contact-col'   >
          <Grid 
            className='contact-col'
            justifyContent="center"
            textAlign="center"
          >
            <Button
                type="submit" 
                variant="contained" 
                sx={{ 
                  backgroundColor: '#4F4A41', 
                  color:'#ffffff',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  width: '120px' , 
                  '&:hover': {backgroundColor: '#E8630A'}, 
                  height: '40px'
                }}
                
              >
              {t('Try Free')}
            </Button>
          </Grid>
        </Grid> */}

      </Grid>
      <Grid
        container
        justifyContent="start"
        className="why-container"
      >
        <Grid className='contact-col'>
          <Typography className="why-title" sx={{fontFamily: "Arial",color:'#4F4A41',marginBottom: '2rem',marginTop: '4rem',textAlign:'start'}} variant="h5">
            <b>{t(`Why choose SME Mate?`)}</b>
          </Typography>
          <Typography className="why-info" sx={{color:'#4F4A41',marginBottom: '0.5rem',textAlign:'start'}} variant="h6">
            {t('We help to create and handle important business documents and requests while ensuring they are kept safe and remain')}<b>{t(' accessible at all times.')}</b>
          </Typography>
        </Grid>
        <List sx={{ width: '100%', bgcolor: 'background.paper', margin: "0 2rem 1rem" }}>
        <Grid container spacing={2} sx={{ margin: "1rem 0 2rem"}}>
            <Grid item xs={12} sm={12} md={3} lg={2} >
              <img src={qcimage} style={{ height: "100%", maxHeight: "150px", margin: "auto 0", display: "block" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10}>
                <Typography variant="h6" fontWeight="bold">
                  {t('Quick Access: ')}
                </Typography>
                <Typography variant="h6">
                    {t('Allow you to easily request or find your documents in our system.')}
                </Typography>
            </Grid>
          </Grid>
          <Divider component="li" />
          <Grid container spacing={2} sx={{ margin: "2rem 0"}}>
            <Grid item xs={12} sm={12} md={3} lg={2} >
              <img src={rqimage} style={{ height: "100%", maxHeight: "150px", margin: "auto 0", display: "block" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10}>
                <Typography variant="h6" fontWeight="bold">
                  {t('24/7 request: ')}
                </Typography>
                <Typography variant="h6">
                  {t('Requesting any documents or ask us to handle them can be done at anytime.')}
                </Typography>
            </Grid>
          </Grid>
          <Divider component="li" />
          <Grid container spacing={2} sx={{ margin: "2rem 0"}}>
            <Grid item xs={12} sm={12} md={3} lg={2} >
              <img src={osimage} style={{ height: "100%", maxHeight: "150px", margin: "auto 0", display: "block" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10}>
                <Typography variant="h6" fontWeight="bold">
                  {t('Immediate Assistance: ')}
                </Typography>
                <Typography variant="h6">
                {t('You have the capability to submit your request around the clock and monitor its progress in real-time, maintaining communication with us throughout the process.')}
                </Typography>
            </Grid>
          </Grid>
          <Divider component="li" />
          <Grid container spacing={2} sx={{ margin: "2rem 0"}}>
            <Grid item xs={12} sm={12} md={3} lg={2} >
              <img src={atimage} style={{ height: "100%", maxHeight: "150px", margin: "auto 0", display: "block" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10}>
                <Typography variant="h6" fontWeight="bold">
                {t('Assistance with all requisite documentation: ')}
                </Typography>
                <Typography variant="h6">
                {t(`Our team is here to assist you with all the essential documentation you require. Whether it's filling out forms, gathering necessary documents, or ensuring everything is properly organized, we've got you covered. Let us handle the paperwork so you can focus on what matters most.`)}
                </Typography>
            </Grid>
          </Grid>
          <Divider component="li" />
          <Grid container spacing={2} sx={{ margin: "2rem 0"}}>
            <Grid item xs={12} sm={12} md={3} lg={2} >
              <img src={mapimage} style={{ height: "100%", maxHeight: "150px", margin: "auto 0", display: "block" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10}>
                <Typography variant="h6" fontWeight="bold">
                {t('Reduce geographic restrictions: ')}
                </Typography>
                <Typography variant="h6">
                {t(`Ensure you still have access to the documents even with remote working or having business in more than one office location.`)}
                </Typography>
            </Grid>
          </Grid>
        </List>
      </Grid>
      <Grid
        container
        justifyContent="center"
      >
     <Grid 
          container
           justifyContent="center" >
          <Grid item xs={12}
    sm={10}
    md={10}
    lg={10}
    xl={10}
   >
          <Typography className='quote' sx={{color:'#4F4A41',marginBottom: '0.5rem',textAlign:'center'}} variant="h6">
            <i>{t(`As a business owner, it's important that you understand how to organize your business efficiently. We make sure that important documents are all sorted quickly and smoothly. SME Mate delivers everything you need and always ready to support you and your business.`)}</i>
          </Typography>
        </Grid>
        </Grid>


        <Grid 
            justifyContent="center"
            textAlign="center"
          >
            <Typography className='quote' sx={{color:'#4F4A41',marginBottom: '1rem',textAlign:'center'}} variant="h6">
              <b>{t(`Let us help you, join us today`)}</b>
            </Typography>
            <Button
                onClick={() => navigate("/register")}
                type="submit" 
                variant="contained" 
                sx={{ 
                  backgroundColor: '#F2903A', 
                  color:'#ffffff',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  width: '120px' , 
                  '&:hover': {backgroundColor: '#E8630A'}, 
                  height: '40px'
                }}
                
              >
              {t('Register')}
            </Button>
          </Grid>
      </Grid>
    </Grid>
  );

};


export default ServicesPage;
