// import { history } from "../index";
import {
  OK,
  REGISTER_FAILED,
  REGISTER_FETCHING,
  REGISTER_SUCCESS,
  REGISTER_USERNAME_FAILED,
  server,
} from "../services/Constants";
import { Register } from "../types/register.type";
import { httpClient } from "../services/httpclient";
const Swal = require('sweetalert2');

export const setRegisterFetchingToState = () => ({
  type: REGISTER_FETCHING,
});

export const setRegisterSuccessToState = (payload: any) => ({
  type: REGISTER_SUCCESS,
  payload,
});

export const setRegisterFailedToState = () => ({
  type: REGISTER_FAILED,
});

export const setRegisterUsernameFailedToState = () => ({
  type: REGISTER_USERNAME_FAILED,
});

export const register = (user: Register, navigate: any) => {
  console.log(user)
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setRegisterFetchingToState());
      // connect
      const result = await httpClient.post(server.MEMBER_URL+"/register", user);
      console.log(result)
      if (result.status == 200 || result.status == 201) {
        Swal.fire({
          title: 'สมัครสมาชิก !!',
          text: 'สมัครสมาชิก สำเร็จ !!',
          icon: 'success',
        })
         dispatch(setRegisterSuccessToState(result.data));
        // setTimeout(() => {
          navigate("/login");
        // }, 2000);
      } else if(result.status == 206) {
        dispatch(setRegisterUsernameFailedToState());
      }
      else {
        dispatch(setRegisterFailedToState());
      }
    } catch (error) {
      // error
      dispatch(setRegisterFailedToState());
    }
  };
};

export const loadMemberByID = (mode:string,member_id:number) => {
  return async(dispatch: any) => {
      dispatch(setRegisterFetchingToState());
      getMemberByID(dispatch,mode,member_id)
  };
};

const getMemberByID = async (dispatch: any,mode:string,member_id:number) => {
    try {
        const result = await httpClient.get<any>(`${server.MEMBER_URL}/getby-id/${mode}/${member_id}`);
        dispatch(setRegisterSuccessToState(result.data));
    }   catch (error) {
        dispatch(setRegisterFailedToState());
    }
}