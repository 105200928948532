import * as React from "react";
import "./Personal_information.css";
import { Stack,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField} from "@mui/material";
import Iframe from "react-iframe";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import getLanguage from "../../Translation/Translation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import * as PersonalInformationAction from "../../../actions/personal_information.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  OK,
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  server,
  TOKEN,
  LOGOUT,
} from "../../../../src/services/Constants";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import * as loginActions from "../../../actions/login.action";
import EditIcon from "@mui/icons-material/Edit";

type HomePageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1,
};



const HomePage: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);
  const personal_informationStateReducer = useSelector((state: RootReducers) => state.personal_informationStateReducer);
  const navigate = useNavigate();
  const [personal_info, setpersonal_info] = React.useState({id:0,name:"",first_name:"",last_name:"",phone_number:"",address:"",member_role:"",user_type:""});
  const [openeditdata, setOpeneditdata] = React.useState(false);
  const [dataname, setdataname] = React.useState("");
  const [datanameedit, setdatanameedit] = React.useState("");

  let avatarname = "";
  let member_id = 0;
  let contact_name = "";
  let contact_phone = "";
  let contact_address = "";
  let member_roll = "";
  let first_name = "";
  let last_name = "";
  if (localStorage.getItem(TOKEN)) {
    const token = JSON.stringify(localStorage.getItem(TOKEN));

    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    member_id = JSON.parse(jsonPayload).user_id ? JSON.parse(jsonPayload).user_id : 0;
    avatarname = JSON.parse(jsonPayload).contact_name ? JSON.parse(jsonPayload).contact_name.slice(0, 1) : "";
    contact_name = JSON.parse(jsonPayload).contact_name ? JSON.parse(jsonPayload).contact_name : "";
    contact_phone = JSON.parse(jsonPayload).contact_phone ? JSON.parse(jsonPayload).contact_phone : "";
    contact_address = JSON.parse(jsonPayload).contact_address ? JSON.parse(jsonPayload).contact_address : "";
    member_roll = JSON.parse(jsonPayload).member_roll ? JSON.parse(jsonPayload).member_roll : "";
    first_name = JSON.parse(jsonPayload).member_roll ? JSON.parse(jsonPayload).member_roll : "";
    last_name = JSON.parse(jsonPayload).member_roll ? JSON.parse(jsonPayload).member_roll : "";
  }

  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(PersonalInformationAction.getPERONAL_INFOMATIONFindByroleId(member_id));
  }, []);

  React.useEffect(() => {
    if(personal_informationStateReducer.result){
      setpersonal_info({...personal_info,id:personal_informationStateReducer.result.id,name:personal_informationStateReducer.result.name,phone_number:personal_informationStateReducer.result.phone_number,
        address:personal_informationStateReducer.result.address,member_role:personal_informationStateReducer.result.role_name,user_type:personal_informationStateReducer.result.user_type,first_name:personal_informationStateReducer.result.first_name,last_name:personal_informationStateReducer.result.last_name});
    }

  }, [personal_informationStateReducer.result]);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const handleEditData = (data:any,name:any) => {
    setdataname(name);
    setdatanameedit(data)
    console.log("edit");
    setOpeneditdata(true);
};
const handleCloseeditData = () => {
  setdataname("");
  setdatanameedit("")
  setOpeneditdata(false);
};
const handleEditDataSubmit = (event:any) => {
  event.preventDefault();
  console.log("SubmitEdit");
let dataAll = {}
if(personal_info.user_type == "Member"){
  if(dataname == "Firstname"){
    dataAll = {id: personal_info.id,first_name:datanameedit}
  }else if(dataname == "Lastname"){
    dataAll = {id: personal_info.id,last_name:datanameedit}
  }else if(dataname == "Phone Number"){
    dataAll = {id: personal_info.id,contact_phone:datanameedit}
  }else if(dataname == "Address"){
    dataAll = {id: personal_info.id,contact_address:datanameedit}
  }else{

  }
}else{
  return;
}
  dispatch(
      PersonalInformationAction.EditPERONAL_INFOMATION(
         dataAll,
        navigate
      )
    );
    handleCloseeditData();
};
  const languages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "th",
      name: "ไทย",
    },
  ];
  
  const showDialogEditData = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditdata}
      onClose={handleCloseeditData}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form onSubmit={handleEditDataSubmit}>
      <DialogTitle id="alert-dialog-title">
      {t("Edit Personal Information")} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <Grid container  >
                        <Grid 
                        sx={{alignSelf:"center"}}
                        item xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
        <Typography  variant="inherit"  component="h6">
    {t(`${dataname}`)} :
    </Typography>
    </Grid>
    
    <Grid item xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email_address"
              size="small"
              onChange={(e) => {setdatanameedit(e.target.value)}}
              value={datanameedit}
            />
             </Grid>
             </Grid>

             
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditData}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }

  return (
    <Grid
      container
      spacing={1}
      // sx={{ ...gridStyles }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
        direction="column"
        item
        container
        sx={{margin: "auto"}}
      >
        <Typography variant="h5" component="div">
          {t("Personal Information")}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={10}
        alignItems="center"
        justifyContent="center"
        direction="row"
        item
        container
        sx={{margin: "auto"}}
      >
        {/* <Grid
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          item
          container
          sx={{ marginRight: "1rem" }}
        >
          <Card sx={{ m: 1, width: "100%" }}>
            <CardContent>
                <Avatar alt={avatarname} sx={{ backgroundColor: "#f4d0b0", color: "#F2903A", width: 100, height: 100, margin: "auto" }} />
                <Typography variant="h5" component="div" gutterBottom sx={{ marginTop: "1rem", textAlign: "center" }}>
                  {contact_name} name
                </Typography>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid> */}

        <Grid
          xs={12}
          sm={12}
          md={10}
          lg={8}
          xl={5}
          alignItems="center"
          justifyContent="center"
          direction="column"
          item
          container
        >
        <Card sx={{ width: "100%",  }}>
          <CardContent>
                <Avatar alt={avatarname} sx={{ backgroundColor: "#f4d0b0", color: "#F2903A", width: 100, height: 100, margin: "auto" }} />
                {/* <Typography variant="h5" component="div" gutterBottom sx={{ marginTop: "1rem", textAlign: "center" }}>
                  {contact_name} name
                </Typography> */}
              <Typography sx={{ color: "#4F4F4F", padding: "1rem", marginTop: "1rem" }} variant="button" component="div" gutterBottom>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="left"
                  justifyContent="left"
                  direction="row"
                  item
                  container
                >
                  <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={5}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                    sx={{ fontWeight: "bold"}}
                  >
                    {t("Firstname")}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                  <Typography sx={{textTransform: 'capitalize' }} variant="button" component="div" gutterBottom>{personal_info.first_name}</Typography>  
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                      {personal_info.user_type == "Member" &&
                   <IconButton
                                aria-label="edit"
                                // size="medium"
                                onClick={() => { handleEditData(personal_info.first_name,"Firstname")}}
                            >
                                <EditIcon 
                                    fontSize="medium" 
                                    sx={{ color:"#964B00"}}  
                                />
                            </IconButton>
                      }
                </Grid>
                </Grid>
                <Divider />
              </Typography>
              <Typography sx={{ color: "#4F4F4F", padding: "1rem", marginTop: "1rem" }} variant="button" component="div" gutterBottom>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="left"
                  justifyContent="left"
                  direction="row"
                  item
                  container
                >
                  <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={5}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                    sx={{ fontWeight: "bold"}}
                  >
                    {t("Lastname")}
                  </Grid>
                  <Grid
                    xs={11}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                     <Typography sx={{textTransform: 'capitalize' }} variant="button" component="div" gutterBottom>{personal_info.last_name}</Typography> 
                  </Grid>
                  <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                      {personal_info.user_type == "Member" &&
                   <IconButton
                                aria-label="edit"
                                // size="medium"
                                onClick={() => { handleEditData(personal_info.last_name,"Lastname")}}
                            >
                                <EditIcon 
                                    fontSize="medium" 
                                    sx={{ color:"#964B00"}}  
                                />
                            </IconButton>
                      }
                </Grid>
                </Grid>
               
                <Divider />
              </Typography>
              <Typography sx={{ color: "#4F4F4F", padding: "1rem" }} variant="button" component="div" gutterBottom>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="left"
                  justifyContent="left"
                  direction="row"
                  item
                  container
                >
                  <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={5}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                    sx={{ fontWeight: "bold"}}
                  >
                    {t("Phone Number")}
                  </Grid>
                  <Grid
                    xs={11}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                    <Typography sx={{textTransform: 'capitalize' }} variant="button" component="div" gutterBottom>{personal_info.phone_number}</Typography> 
                </Grid>
                <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                      {personal_info.user_type == "Member" &&
                   <IconButton
                                aria-label="edit"
                                // size="medium"
                                onClick={() => { handleEditData(personal_info.phone_number,"Phone Number")}}
                            >
                                <EditIcon 
                                    fontSize="medium" 
                                    sx={{ color:"#964B00"}}  
                                />
                            </IconButton>
                      }
                </Grid>

                
                </Grid>
                <Divider />
              </Typography>
              <Typography sx={{ color: "#4F4F4F", padding: "1rem"  }} variant="button" component="div" gutterBottom>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="left"
                  justifyContent="left"
                  direction="row"
                  item
                  container
                >
                  <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={5}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                    sx={{ fontWeight: "bold"}}
                  >
                     {t("Address")}
                  </Grid>
                  <Grid
                    xs={11}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                    sx={{ lineBreak: "anywhere"}}
                  >
                    <Typography sx={{textTransform: 'capitalize' }} variant="button" component="div" gutterBottom>{personal_info.address}</Typography>
                  </Grid>
                   <Grid
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                    {personal_info.user_type == "Member" &&
                   <IconButton
                                aria-label="edit"
                                // size="medium"
                                onClick={() => {handleEditData(personal_info.address,"Address")}}
                            >
                                <EditIcon 
                                    fontSize="medium" 
                                    sx={{ color:"#964B00"}}  
                                />
                            </IconButton>
                            }
                </Grid>
                </Grid>
                <Divider />
              </Typography>
              <Typography sx={{ color: "#4F4F4F", padding: "1rem"  }} variant="button" component="div" gutterBottom>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="left"
                  justifyContent="left"
                  direction="row"
                  item
                  container
                >
                  <Grid
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={5}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                    sx={{ fontWeight: "bold"}}
                  >
                    {t(`Member Role`)}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    alignItems="left"
                    justifyContent="left"
                    direction="row"
                    item
                    container
                  >
                     <Typography sx={{textTransform: 'capitalize' }} variant="button" component="div" gutterBottom>{personal_info.member_role}</Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Typography>
          </CardContent>
          <CardActions></CardActions>
        </Card>
        {showDialogEditData()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
